<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense"></ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/admin/dashboard')"
          src="assets/ICONOS/close.svg"></ion-icon>

              <p class="tabTitle">Monitor de Sucursales

                <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg" 
                    @click="openModal('Monitor de Sucursales', 'Nuestro sistema de monitoreo en tiempo real proporciona información detallada sobre las sucursales, incluyendo su estado de conexión, la música que están reproduciendo, y otros parámetros relevantes. A continuación, se detalla la estructura de la información monitoreada:', 

[
  {
    id: 0,
    mrg: 10,
    name: 'Identificación de la Sucursal:'
  },
  {
    id: 1,
    mrg: 10,
    name: 'Sin Identificar: La sucursal no ha seleccionado su nombre.'
  },
  {
    id: 2,
    mrg: 10,
    name: 'Desconocido: Intento de conexión desde una sucursal no registrada.'
  },
  {
    id: 3,
    mrg: 10,
    name: 'Nombre de la Sucursal: Indica el nombre cuando la sucursal está correctamente identificada.'
  },
  {
    id: 4,
    mrg: 10,
    name: 'IP: La dirección IP desde la cual se conecta la sucursal. Ejemplo: 189.204.171.66.'
  },
  {
    id: 5,
    mrg: 10,
    name: 'Categoría: La categoría musical en la que está la sucursal. Puede ser |Base| u otro género musical específico.'
  },
  {
    id: 6,
    mrg: 10,
    name: 'Token: Número consecutivo de reproducción del día en el momento de la última conexión. Ejemplo: 452.'
  },
  {
    id: 7,
    mrg: 10,
    name: 'ID: ID único del audio que se está reproduciendo en ese momento o en la última conexión. Ejemplo: 68916.'
  },
  {
    id: 8,
    mrg: 10,
    name: 'Audio: Nombre de la canción o del audio que se está reproduciendo en ese momento o en la última conexión. Ejemplo: La canción de la rama.'
  },
  {
    id: 9,
    mrg: 10,
    name: 'Tiempo sin Conexión: Minutos que la sucursal ha estado sin notificar lo que está reproduciendo. Ejemplo: 0 min sin conexión.'
  },
  {
    id: 10,
    mrg: 10,
    name: 'Volumen: El volumen que tenía el reproductor la última vez que notificó. Ejemplo: 100%.'
  },
  {
    id: 11,
    mrg: 10,
    name: 'Estado de Reproducción: Muestra si el reproductor estaba en |Reproduciendo| o |Pausado| la última vez que notificó.'
  },
  {
    id: 12,
    mrg: 20,
    name: 'Frecuencia de Notificación y Estado de Conexión: Cada sucursal notifica su estado de conexión cada 2 minutos con 30 segundos. Si una sucursal tarda más de 5 minutos sin conectarse, el estado de la conexión cambiará de color. Verde: Todo correcto. Amarillo: Alerta (volumen bajo y/o reproductor en pausa). Rojo: Crítico (más de 5 minutos sin conexión).'
  },
  {
    id: 13,
    mrg: 10,
    name: 'Ejemplo de Información Monitoreada: Sucursal:'
  },
  {
    id: 14,
    mrg: 5,
    name: 'Nombre de la Sucursal / Sin Identificar / Desconocido'
  },
  {
    id: 15,
    mrg: 5,
    name: 'IP: 189.204.171.66 Categoría: Base'
  },
  {
    id: 16,
    mrg: 5,
    name: 'Token: 452'
  },
  {
    id: 17,
    mrg: 5,
    name: 'ID: 68916'
  },
  {
    id: 18,
    mrg: 5,
    name: 'Audio: La canción de la rama'
  },
  {
    id: 19,
    mrg: 5,
    name: 'Tiempo sin Conexión: 0 min sin conexión'
  },
  {
    id: 20,
    mrg: 5,
    name: 'Volumen: 100%'
  },
  {
    id: 21,
    mrg: 20,
    name: 'Estado de Reproducción: Reproduciendo / Pausado'
  },
  {
    id: 22,
    mrg: 0,
    name: 'Este formato permite un monitoreo efectivo y en tiempo real de cada sucursal, asegurando que todas las instancias de reproducción estén correctamente identificadas y categorizadas, y permite detectar rápidamente cualquier problema de conexión o reproducción.'
  }
])"
                    />

              </p>

                <select class="channel_select_e" v-model="selectedChannel" @change="handleChannelChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                  <option v-for="item in channel_name_list" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select> 
                
                <span style="margin-left: 12px;">Actualizando en {{ actualizationInterval }} seg.</span>
                <button class="detallesExtraButton" 
                @click="detallesExtra = !detallesExtra"  style="margin-right: 12px;" >
                  <img v-if="detallesExtra" src="img-admin/view.png">
                  <img v-else src="img-admin/hide.png">
                  <span v-if="detallesExtra"> Vista Detallada</span>
                  <span v-else> Vista Compacta</span>
                </button>
                
                
                <div class="admin_report_filter">
                  <div class="filter-input-con" >
                    <ion-input class="filterInput" placeholder="Filtrar por sucursal, variante o IP" @keyup="filterConnections" ref="textFilter"></ion-input>
                    <ion-icon class="filterIcon filtersearch" src="assets/ICONOS/search.svg"></ion-icon>
                    <ion-icon src="assets/ICONOS/close_f.svg" class="filterIcon filterclose" @click="this.$refs.textFilter.value = ''; filterConnections();"></ion-icon>
                  </div> 
                </div>

                <ion-card v-if="errorConectados" color="danger" class="connectionalert outline-card" >
                  <ion-card-header>
                    <ion-card-title>Error</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    Error al cargar las sucursales, intente de nuevo mas tarde o reinicie la aplicación borrando caché. Si el error persiste contacte a su asesor de marca enviando una captura de pantalla de este mensaje.
                  </ion-card-content>
                </ion-card>

                <ion-card v-if="vacioConectados" color="warning" class="connectionalert outline-card" ouline>
                  <ion-card-header>
                    <ion-card-title>Sin sucursales conectadas.</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    El día de hoy no hay sucursales conectadas. Recuerda que solo se muestran las conexiones por día.
                  </ion-card-content>
                </ion-card>

                <div class="connection-grid" v-if="cargandoConectados">
                  <ion-grid class="">
                    <ion-row class="">
                        <ion-col class=" connection-item" v-for="index in 4" :key="index" 
                        size="12" size-sm="6" size-md="4" size-lg="3"> 
                        <ion-card color="light" class="statusConnectedCard wrapper-card loader-card">
                          <ion-card-header> 
                            <ion-card-subtitle class="sucName">
                              Cargando...
                            </ion-card-subtitle>
                          </ion-card-header>
                          <ion-card-content> 
                            <div class="connectedCard">
                              <div class="statusBar">
                                  <div class="conected">
                                    <img src="img-admin/connected-g.png" >
                                    <span><strong>0</strong>min sin conexion</span>
                                  </div>
                                  <div class="volume">
                                    <img src="img-admin/volumelow.png" >
                                    <span>Volumen: <strong>0%</strong></span>
                                  </div>
                                  <div class="playing">
                                    <img src="img-admin/play.png" >
                                    <span>Reproduciendo</span>
                                  </div>
                              </div>
                              <span class="version">v<strong>0.0.0</strong></span>
                            </div>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>

                <div class="connection-grid">
                  <ion-grid>
                    <ion-row>
                      <ion-col v-for="connection in connectionsFiltered" :key="connection.id" class="connection-item" 
                      size="12" size-sm="6" size-md="4" size-lg="3">
                        <ion-card color="light" class="statusConnectedCard"
                          :class="
                          connection.minutes <= 5 && connection.volume >= 50 && connection.playing == true ? 'statusConnected' : 
                          connection.minutes > 10 || connection.volume < 25 || connection.playing == false ? 'statusDisconnected' : 
                          connection.minutes <= 10 || connection.volume >= 25 ? 'statusAlmostConnected' : 'error'
                          "
                        >
                          <ion-card-header> 
                            <ion-card-subtitle class="sucName" v-if="connection.sucursal_name === '' && connection.variant_name === ''">
                              Sin Identificar
                            </ion-card-subtitle>
                            <ion-card-subtitle class="sucName" v-else-if="connection.sucursal_name === connection.variant_name">
                              {{ 'ID/VAR: ' + connection.sucursal_name}}
                            </ion-card-subtitle>
                            <ion-card-subtitle class="sucName" v-else-if="connection.sucursal_name !== '' && connection.variant_name !== ''">
                              {{ 'ID: ' + connection.sucursal_name + ' VAR: ' + connection.variant_name }}
                            </ion-card-subtitle>
                            <ion-card-subtitle class="sucName" v-else-if="connection.sucursal_name !== ''">
                              {{ 'ID: ' + connection.sucursal_name }}
                            </ion-card-subtitle>
                            <ion-card-subtitle class="sucName" v-else-if="connection.variant_name !== ''">
                              {{ 'VAR: ' + connection.variant_name }}
                            </ion-card-subtitle>
                            <ion-card-subtitle class="sucName" v-else>
                              Sin Identificar
                            </ion-card-subtitle>
                            <small class="detallesExtra" v-show="detallesExtra">
                              <span class="ip">IP: <strong>{{ connection.ip }}</strong></span><br>
                              <span class="cat">Cat: <strong>{{ connection.cat_name }}</strong></span><br>
                              <span class="token">Token: <strong>{{ connection.token }}</strong></span><br>
                              <span class="audioid">ID: <strong>{{ connection.audio_id }}</strong></span> 
                              <p class="audioname">Audio: <strong>{{ connection.audio_name }}</strong></p>
                            </small>
                          </ion-card-header>
                          <ion-card-content> 
                            <div class="connectedCard">
                              <div class="statusBar">
                                  <div class="conected">
                                    <img v-if="connection.minutes <= 5" src="img-admin/connected-g.png" >
                                    <img v-else-if="connection.minutes <= 10" src="img-admin/disconnected-y.png" >
                                    <img v-else src="img-admin/disconnected-r.png" >
                                    <span><strong>{{ connection.minutes }} </strong>min sin conexion</span>
                                  </div>
                                  <div class="volume">
                                    <img v-if="connection.volume >= 50" src="img-admin/volumeup.png" >
                                    <img v-else-if="connection.volume >= 25" src="img-admin/volumelow.png" >
                                    <img v-else-if="connection.volume < 25" src="img-admin/volumedown.png" >
                                    <span>Volumen: <strong>{{ connection.volume }}%</strong></span>
                                  </div>
                                  <div class="playing">
                                    <img v-if="connection.playing" src="img-admin/play.png" >
                                    <img v-else src="img-admin/pause.png" >
                                    <span>{{ connection.playing ? 'Reproduciendo' : 'Pausado' }}</span>
                                  </div>
                              </div> 
                              <span class="version">v<strong>{{ connection.version }}</strong></span>
                            </div>
                          </ion-card-content>
                        </ion-card> 
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                  
                </div>

                <div>
                  <p class="tabTitle" v-if="disconnections.length>0">
                    Sucursales sin Identificar
                  </p>
                  <ion-grid>
                    <ion-row>
                      <ion-col v-for="disconnection in disconnections" :key="disconnection.id" class="connection-item" 
                      size="12" size-sm="6" size-md="4" size-lg="3">
                        <ion-card color="light" class="statusConnectedCard" >
                          <ion-card-header> 
                            <ion-card-subtitle class="sucName">
                              {{ disconnection.name }}
                            </ion-card-subtitle>
                          </ion-card-header>
                          <!--<ion-card-content> 
                            <div class="connectedCard">
                              <div class="statusBar">
                                  <div class="conected">
                                     asd
                                  </div>
                              </div>  
                            </div>
                          </ion-card-content>-->
                        </ion-card> 
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
         
      </div>
    </ion-content>
    <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
  </ion-page>
</template>


<script>
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent, modalController } from '@ionic/vue';
import axios from 'axios';
import infoModal from '../infoModal.vue';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from '../header_toolbar.vue';
import { Capacitor } from '@capacitor/core';
import 'url-polyfill';
import 'blob-polyfill'; 
//import { toastController } from '@ionic/vue';

export default ({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, IonRefresherContent },
  data() {
    return {
      urlApi: 'https://panel.vybroo.net/',
      clientData: null, 
      clipContainer: null, 
      token: null,
      serverDate: null,
      clientData: {},
      detallesExtra: false,
      connections: [],
      connectionsFiltered: [],
      cargandoConectados: true,
      errorConectados: false,
      vacioConectados: false,
            /*"id": 6,
            "stream": "2box",
            "suc": 0,
            "var": 0,
            "cat": 0,
            "datetime": "2024-03-19 11:41:15",
            "ip": "192.168.222.191",
            "playing": true,
            "volume": 90,
            "audio_id": 2,
            "version": "1.2.1"*/
      disconnections: [],
      disconnections_data: [],
      channel_name_list: [],
      channel_list: [],
      selectedChannel: null,
      dashboard: 
        {
          client: {
            email: "",
            id: 0,
            name: "",
            obligation: 0,
            retainer: 0
          },
          radio: {
            blacklist: "",
            branches: 0,
            categories: "",
            image: "2f07f7cfc8d4348937078a566c7d943e.png",
            link: "",
            mobileimage: "6b8b53effc9683373db10671d4bf0687.png",
            name: "",
            tags_blacklist: "",
            video_enabled: false
          },
          spots: {
            id: 0,
            level: 0,
            month: "",
            name: "",
            obligation: 0,
            percentage: "",
            produced: null,
            year: ""
          },
        },
      actualizationInterval: null,
    }
  },
  methods: {
    async openModal(title, desc, list) {
      const modal = await modalController.create({
        component: infoModal,
        componentProps: {
          title: title,
          desc: desc,
          list: list,
        },
      });
      return modal.present();
    },
    filterConnections() {
      let filter = (this.$refs.textFilter.value).toLowerCase();
      //console.log('filter:', filter);
      this.connectionsFiltered = this.connections.filter(connection => {
        return connection.sucursal_name.toLowerCase().includes(filter) ||
              connection.variant_name.toLowerCase().includes(filter) ||
              connection.ip.toLowerCase().includes(filter);
      });
      //console.log('filter:', filter);
      //console.log('this.connections:', this.connections);
      //console.log('this.connectionsFiltered:', this.connectionsFiltered);
    },
    handleChannelChange(event) {
      //console.log('conexiones:this.disconnections:', this.disconnections);
      //console.log('conexiones:this.disconnections_data:', this.disconnections_data);
      
      this.getDisconnectedData();
      //console.log('conexiones:Canal seleccionado:', event.target.value);
      //this.loadChannelDashboard(event.target.value);
      this.selectedChannel = event.target.value;
      this.emitter.emit("selectedChannel", event.target.value);
              Storage.set({
                key: "cabina_admin_selectedChannel",
                value: this.selectedChannel,
              }); 
      setTimeout(() => {
        this.getConnections();
      }, 1000);
    },
    getConnectionsLoop() {
      this.actualizationInterval = 30;
      setInterval(() => {
        this.getConnections();
      }, 30000);
      setInterval(() => {
        this.actualizationInterval--;
        if (this.actualizationInterval == 0) {
          this.actualizationInterval = 30;
        }
      }, 1000);
    },
    async getConnections() { 
      /*
      toastController.create({
        message: 'Recargando Sucursaless...',
        duration: 5000,
        color: 'secondary' 
      }).then((toast) => {
        toast.present();
      });// dark, success, danger, warning, secondary, primary
      */
      //console.log("CONEXIONES: getConnections");
      //console.log("CONEXIONES: this.selectedChannel:", this.selectedChannel);
      //console.log("CONEXIONES: this.dashboard.radio.stream:", this.dashboard.radio.stream);
      this.connections = [];
      this.connectionsFiltered = [];
      this.cargarConectados = true;
      this.errorConectados = false;
      this.vacioConectados = false;
      //console.log("---CONEXIONES: this.clientData:", this.clientData );
      try {
        let url = "api/connections";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/connections";
            await axios.post(url, { 
                        token: this.token, 
                        stream: this.dashboard.radio.stream+""
                      })
            .then(r => {
              //console.log("---CONEXIONES: r.data:", r.data);  

              let connectionsAux = r.data.data; 
              this.serverDate = r.data.date;
              //console.log("---CONEXIONES: this.serverDate:", this.serverDate); 

              let conAuxConnected = [];
              let conAuxDisconnected = [];
              let conAuxDisconnectedPV = [];
              let conAuxAlmostConnected = [];
              if (connectionsAux != null && connectionsAux.length > 0) {
                connectionsAux.forEach(connection => {
                  if (connection.var == 0) {
                    connection.variant_name = "";
                  }else{
                    let variant = this.dashboard.radio.ContentVariants.find(variant => variant.id == connection.var);
                    connection.variant_name = variant ? variant.name : "Desconocido";
                  }
                  if (connection.suc == 0) {
                    connection.sucursal_name = "";
                  }else{ 
                    let suc = this.dashboard.radio.identifiers.find(identifiers => identifiers.id == connection.suc);
                    connection.sucursal_name = suc ? suc.identifier : "Desconocido";
                  }
                  if (connection.cat == 0) {
                    connection.cat_name = "Base";
                  }else{
                    let cat = this.dashboard.radio.MusicVariants.find(music_variants => music_variants.id == connection.cat);
                    connection.cat_name = cat ? cat.name : "Desconocido";
                  }
                  let minutes = Math.floor((new Date(this.serverDate) - new Date(connection.datetime)) / 60000);
                  connection.minutes = minutes;
                  //------------------------
                  if (minutes <= 5 && connection.volume >= 50 && connection.playing == true) {
                    connection.ztatus = "Conectado";
                    conAuxConnected.push(connection);
                  } else if (minutes > 10 || connection.volume < 25 || connection.playing == false) {
                    connection.ztatus = "Desconectado";
                    if (minutes < 10) {
                      conAuxDisconnectedPV.push(connection);
                    }else{
                      conAuxDisconnected.push(connection);
                    }
                  } else if (minutes <= 10 || connection.volume >= 25) {
                    connection.ztatus = "Casi Conectado";
                    conAuxAlmostConnected.push(connection);
                  } else {
                    //console.log("Error en la conexión: ", connection);
                  }

                });
              }else{
                this.vacioConectados = true;
              }
              
              this.connections = [];
              this.connectionsFiltered = [];
              this.connections = conAuxDisconnected.concat(conAuxDisconnectedPV).concat(conAuxAlmostConnected).concat(conAuxConnected);
              this.filterConnections();
              //console.log("CONEXIONES: this.connections:", this.connections);

              this.disconnections = [];
              // recorrer el disconnections_data y buscar en connections si no existe el elemento en connections, agregarlo a disconnections solo el nombre, sin permitir repeticiones en disconnections
              if (this.disconnections_data != null && this.disconnections_data.length > 0) {
                this.disconnections_data.forEach(disconnection => {
                  let exist = this.connections.find(connection => connection.sucursal_name == disconnection.name || connection.variant_name == disconnection.name);
                  if (!exist) {
                    this.disconnections.push({ name: disconnection.name ? disconnection.name : "Desconocido"});
                  }
                });
              }
              this.cargandoConectados = false;
              this.errorConectados = false;

            }).catch(error => {
              //console.log("CONEXIONES: 1Error en función validateToken de Conexiones: " + error.message); 
              this.connections = [];
              this.cargandoConectados = false;
              this.errorConectados = true;
            }); 
      } catch (error) {
        //console.log("CONEXIONES: 2Error en función validateToken de Conexiones: " + error.message); 
        this.connections = [];
        this.cargandoConectados = false;
        this.errorConectados = true;
      }
      this.actualizationInterval = 30;
    },
    async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: buttonTextConfirm,
              handler: () => {
                functionConfirm();
              }
            },
            {
              text: buttonTextCancel,
              handler: () => {
                functionCancel();
              }
            }
          ]
        });
      return alert.present();
    },
    async signOut() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                //console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();

    }, 
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
      } catch (error) {
        //console.log("Error en función getStorageClientData de Conexiones: " + error.message);
      }
    },
    async getDisconnectedData() {
      try {
        this.disconnections=[];
        this.disconnections_data=[];
        let disconnectionsAux=[];
            disconnectionsAux.variants = this.dashboard.radio.ContentVariants;
            disconnectionsAux.identifiers = this.dashboard.radio.identifiers;
        disconnectionsAux.variants.forEach(variant => {
          let exist = this.disconnections_data.find(disconnections_data => disconnections_data.name == variant.name);
          if (!exist) {
            this.disconnections_data.push({ name: variant.name });
            //console.log("addingDisc - variant.name: ", variant.name);
          }
        });
        disconnectionsAux.identifiers.forEach(identifier => {
          let exist = this.disconnections_data.find(disconnections_data => disconnections_data.name == identifier.identifier);
          if (!exist) {
            this.disconnections_data.push({ name: identifier.identifier });
            //console.log("addingDisc - identifier.identifier: ", identifier.identifier);
          }
        });
        this.disconnections = this.disconnections_data;
        // console.log(this.clientData);
      } catch (error) {
        //console.log("Error en función getDisconnectedData de Conexiones: " + error.message);
      }
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getStorageAuthToken() {
      try {
        //console.log("Obteniendo token del storage...")
        const ret = await Storage.get({ key: 'authToken' });
        if (ret.value) {
          const tokenObj = JSON.parse(ret.value);
          this.token = tokenObj.value;
          //console.log("Token obtenido: " + this.token);
          // console.log("Token obtenido:" + this.token)
          return this.validateToken(this.token);
        } else {
          //console.log("Token no encontrado");
          this.presentAlertTokenInvalid();
          return false;
        }
      } catch (error) {
        //console.log("Error en función getStorageAuthToken de Conexiones: " + error.message);
      }
    },
    async presentAlertTokenInvalid() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.$router.replace("/admin/LoginAdmin");
              }
            },
          ]
        });
      return alert.present();
    },
    async validateToken(token) {
      try {
        //console.log("revisando validez de token: " + token + " ...");
        if (token == null || token == undefined || token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (token != null && token != undefined && token != "") {
          let url = "api/user_auth";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
          let response = await axios.post(url, { token: token })
            .then(r => {
              //console.log("r.data:");
              //console.log(r.data);
              return r;
            }).catch(error => {
              //console.log("3Error en función validateToken de Conexiones: " + error.message);
              return error;
            });
          //console.log("response.data:");
          //console.log(response.data);
          //console.log("response.data.success: " + response.data.success);
          //console.log("response.data.error: " + response.data.error);
          if (response.data.error === true) {
            //console.log("Error al validar token:");
            //console.log(response.data);
            this.presentAlertTokenInvalid();
            return false;
          }
          if (response.data.success === true) {
            //console.log("Token válido");
            return true;
          } else {
            //console.log("Token inválido");
            //console.log("2response.data.success: " + response.data.success);
            //console.log("2response.data.error: " + response.data.error);
            this.presentAlertTokenInvalid();
            return false;
          }
        }
      } catch (error) {
        //console.log("4Error en función validateToken de Conexiones: " + error.message);
        return false;
      }
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          //console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          //console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    async getChannelList(){
      try {
        const ret = await Storage.get({ key: "cabina_admin_channel_list" });
        if (ret.value) { 
            this.channel_list = JSON.parse(ret.value).slice();
            this.emitter.emit("channel_list", this.channel_list);
            this.channel_name_list = [];
            if (this.channel_list){
              this.channel_list.forEach(element => {
                this.channel_name_list.push({ id: element.id, name: element.name });
              });
              this.emitter.emit("channel_name_list", this.channel_name_list);
              //console.log("Recuperado de storage, key: cabina_admin_channel_list - " , this.channel_list);
              //console.log("Recuperado de storage, key: channel_name_list - " , this.channel_name_list);
              if (this.selectedChannel == [] || this.selectedChannel == null || this.selectedChannel == undefined) {
                const selectedChannelAux = await Storage.get({ key: "cabina_admin_selectedChannel" });
                //console.log("selectedChannelAux: " , selectedChannelAux);
                if (selectedChannelAux.value) {
                  this.selectedChannel = selectedChannelAux.value;
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                } else {
                  this.selectedChannel = this.channel_list[0].id;
                  this.loadChannelDashboard(this.selectedChannel);
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                }
              }
              //this.selectedChannel = this.channel_list[0].id;
              //this.loadChannelDashboard(this.selectedChannel);
              //this.emitter.emit("selectedChannel", this.selectedChannel);
            }
        } else {
          console.log("No se pudo recuperar de storage, key: cabina_admin_channel_list - " , this.channel_list);
        }
      } catch (error) {
        console.log("Error en función cabina_admin_channel_list de Dashboard: " , error.message);
        return false;
      }
    }
  },
  mounted: function () { 
setTimeout(() => {

    try {
      if (Capacitor.getPlatform() == 'ios') {
        this.urlApi = "https://panel.vybroo.net/";
      } else {
        this.urlApi = "/api/";
      } 

      //get auth token de storage y asignarlo a la variable token
      this.getStorageAuthToken();
      this.getStorageClientData('clientData');
      this.getChannelList();

      this.emitter.on("selectedChannel", (channel) => {
        //console.log("conexiones::::emitedON:selectedChannel: " , channel);
        this.selectedChannel = channel;
      });
      this.emitter.on("channel_list", (channel_list) => {
        //console.log("conexiones::::emitedON:channel_list: " , channel_list);
        this.channel_list = channel_list;
      });
      this.emitter.on("channel_name_list", (channel_name_list) => {
        //console.log("conexiones::::emitedON:channel_name_list: " , channel_name_list);
        this.channel_name_list = channel_name_list;
      });
      this.emitter.on("dashboard", (dashboard) => {
        //console.log("conexiones::::emitedON:dashboard: " , dashboard);
        this.dashboard = dashboard;
        this.getDisconnectedData();
      });
 
    } catch (error) {
      console.log("Error en función mounted de Conexiones: " + error.message);
    }

    
    this.getConnectionsLoop();
}, 1000);
  setTimeout(() => {
    this.getConnections(); 
  }, 2000);
  },
  

})
</script>
<style lang="css">
.detallesExtraButton { 
  right: 10px;
  top: 10px; 
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  color: #FFF;
  background-color: #000;
  border: solid 1px #fff;
  float: right;
}
.detallesExtraButton img {
  height: 15px;
  margin-bottom: -3px; 
}

.connection-item { 
   
 
}

.statusConnectedCard::before {
    content: "";
    height: 100%;
    width: 5px;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
}
.statusConnected::before {
  background: #00ff00 !important;
}
.statusAlmostConnected::before {
  background: #ffa200 !important;
}
.statusDisconnected::before {
  background: #ff0000 !important;
}
.statusConnectedCard {
  position: relative;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 5px;
}
.statusConnectedCard ion-card-header {
    padding: 6px;
}
.statusConnectedCard ion-card-content {
    padding: 0px 6px 6px 6px;
}
.connectedCard { 
}
.connectedCard .version {
  font-size: 10px;
    padding-left: 5px;
    position: absolute;
    right: 5px;
    bottom: 0;
    color: #858585;
}
.sucName {  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sucName:hover{
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}
.audioname {  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  margin: 2px 0 0 0;
}
.audioname:hover {
  white-space: normal;
  overflow: visible;
  text-overflow: unset; 
}
.connectedCard .version strong {
  font-size: 11px; 
    color: #c9c9c9;
}
.connectedCard .statusBar {}
.connectedCard .statusBar .conected ,
.connectedCard .statusBar .volume ,
.connectedCard .statusBar .playing  {
   
}
.connectedCard .statusBar .conected img,
.connectedCard .statusBar .volume img,
.connectedCard .statusBar .playing img {
  height: 20px;
  margin-bottom: -5px;
}
.connectedCard .statusBar .conected span,
.connectedCard .statusBar .volume span,
.connectedCard .statusBar .playing span{
  line-height: 25px;
  font-size: 14px;
  padding-left: 5px;
}
.connectedCard .infoBar {}
  
.outline-card {
  position: relative;
  color: #FFF;
}
.outline-card::before {
  content: "";
    background: #000;
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 5px;
    opacity: .9;
}


.connectionalert {
  width: 450px;
  margin: 10px auto;
  max-width: 95%;
}


.loader-card {
  position: relative;
  overflow: hidden;
  opacity: .6;
  filter: blur(1.5px);
}

.loader-card:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(110deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, rgba(227, 227, 227, 0.5) 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
  animation: gradient-animation_2 1.8s linear infinite;
}

.loader-card .connectedCard .statusBar .conected img,
.loader-card .connectedCard .statusBar .volume img,
.loader-card .connectedCard .statusBar .playing img {
  filter: brightness(10) grayscale(10);
  opacity: .8;
  animation: parpadeo-icon-loader 1.2s linear infinite;
}

.loader-card .wrapper-card {
  width: 100%;
  height: 100%;
  position: relative;
}

.loader-card .wrapper-card > div {
   
}

.loader-card .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.loader-card .button {
  display: inline-block;
  height: 32px;
  width: 75px;
}

.loader-card .line-1 {
  position: absolute;
  top: 11px;
  left: 0px;
  height: 10px;
  width: 100%;
}

.loader-card .line-2 {
  position: absolute;
  top: 34px;
  left: 0px;
  height: 10px;
  width: 92%;
}

.loader-card .line-3 {
  position: absolute;
  top: 57px;
  left: 0px;
  height: 10px;
  width: 100%;
}

.loader-card .line-4 {
  position: absolute;
  top: 80px;
  left: 0px;
  height: 10px;
  width: 92%;
}
 

@keyframes gradient-animation_2 {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}
@keyframes parpadeo-icon-loader {
  0% {
    opacity: .8;
  }
  50% {
    opacity: .4;
  }
  100% {
    opacity: .8;
  }
}









@media screen and (max-width: 400px) {
.detallesExtraButton span {
  display: none;
}
}
</style>